import React from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import AppsIcon from '@mui/icons-material/Apps';
import StarRateIcon from '@mui/icons-material/StarRate';
import GroupIcon from '@mui/icons-material/Group';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import HailIcon from '@mui/icons-material/Hail';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AppBarDrawerCombo from "../components/common/AppBarDrawerCombo";
import { ADMIN_ROLE, ANY_ROLE, HR_ROLE } from "../utils/constants";
import { useUserAPI } from "../context/userAPIContext";

const AuthPageLayout = (props) => {
  const navigate = useNavigate();
  const { user } = useUserAPI();

  const sideBarMenuItems = [
    {
      text: "Categories",
      icon: AppsIcon,
      onClick: () => navigate("/dashboard"),
      roles: [ANY_ROLE]
    },
    {
      text: "Skills",
      icon: StarRateIcon,
      onClick: () => navigate("/skills"),
      roles: [ANY_ROLE],
    },
    {
      text: "Users",
      icon: GroupIcon,
      onClick: () => navigate("/users"),
      roles: [ADMIN_ROLE],
    },
    {
      text: "Teams",
      icon: WorkspacesIcon,
      onClick: () => navigate("/teams"),
      roles: [ADMIN_ROLE],
    },
    {
      text: "Clients",
      icon: HailIcon,
      onClick: () => navigate("/clients"),
      roles: [ADMIN_ROLE, HR_ROLE],
    },
    {
      text: "Positions",
      icon: ManageAccountsIcon,
      onClick: () => navigate("/positions"),
      roles: [ADMIN_ROLE, HR_ROLE],
    }
  ];

  const getMenuItemsAccordingToUserRole = () => {
    return sideBarMenuItems.filter((item) => item.roles.includes(ANY_ROLE) || (user.roles.demo && user.roles.demo.some(role => item.roles.includes(role))));
  }

  return (
    <Box sx={{ display: "flex" }}>
      <AppBarDrawerCombo
        listItems={getMenuItemsAccordingToUserRole()}
        drawerWidth={240}
      />
      <Box
        component="main"
        sx={{
          backgroundColor: '#e7eaf6',
          flexGrow: 1,
          height: "100vh",
          overflow: "auto"
        }}
      >
        <Toolbar />
        <Container
          maxWidth={props.containerWidth || "md"}
          sx={{ mt: 4, mb: 4 }}
        >
          {props.children}
        </Container>
      </Box>
    </Box>
  );
};

export default AuthPageLayout;
