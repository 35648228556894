import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import ioetLogo from "../assets/images/ioet-logo.png";
import { APP_NAME } from "../utils/constants";
import EnvManager from "../config/envManager";

const loginURL = `${EnvManager.AUTH_URL}/authn/login/${APP_NAME}`;

const SkipAuthButton = () => {

  const generateMockCookie = () => {
    document.cookie = "session=mockValue; max-age=3600; path=/";
  };

  return (
    <Button variant="contained" size="large" onClick={generateMockCookie} href="/">
      Login
    </Button>
  );
};

function Login() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: "80vh" }}
    >
      <img src={ioetLogo} alt="ioet" width="10%" />
      {EnvManager.SKIP_AUTH ? (
        <SkipAuthButton />
      ) : (
        <Button variant="contained" size="large" href={loginURL}>
          Login
        </Button>
      )}
    </Grid>
  );
}

export default Login;
