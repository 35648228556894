import axios from "axios";
import EnvManager from '../../config/envManager';

const backend = axios.create({
  baseURL: `${EnvManager.BACKEND_URL}/api`,
  withCredentials: true
});

export const getUsers = async () => {
  try {
    const response = await backend.get('/users');
    return response.data;
  }
  catch (error) {
    return null;
  }
};

export const postUser = async () => {
  try {
    const response = await backend.post('/users');
    return response.data;
  } catch (error) {
    return null;
  }
};
