import { Grid, Paper } from "@mui/material";
import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";

import AuthPageLayout from "../layout/AuthPageLayout";
import LoadingIndicator from "../components/common/LoadingSpinner";
import { useUserAPI } from "../context/userAPIContext";
import { CustomToolbar } from "../components/common/CustomDataGridToolBar";
import { CustomModal } from "../components/common/CustomModal";
import { ADMIN_ROLE } from "../utils/constants";
import { getSkills, postSkill } from "../services/api/skills";

const createSkillModalProps = { 
  title: 'Create Skill',
  description:
    'Backend response to create skill:',
  confirmButtonText: 'Create',
  cancelButtonText: 'Cancel',
  confirmButtonColor: 'primary',
  variant: 'outlined',
};

export const Skills = () => {

  const skillsTableColumns = [
    {
      field: 'type',
      headerName: 'TYPE',
      minWidth: 150,
      flex: 1,
      headerClassName: 'header-style',
      headerAlign: 'center'
    },
    {
      field: 'description',
      headerName: 'DESCRIPTION',
      minWidth: 150,
      flex: 1,
      headerClassName: 'header-style',
      headerAlign: 'center'
    }
  ];

  const isAdmin = () => user.roles.demo && user.roles.demo.includes(ADMIN_ROLE);

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [skills, setSkills] = useState([]);
  const { user, checkUserAuthentication } = useUserAPI();
  const [ messageCreate, setMessageCreate] = useState('');

  const createSkill = async () => {
    setOpenCreateModal(true)
    const message = await postSkill();
    setMessageCreate(message);
  };

  useEffect(() => {
    checkUserAuthentication();
    const fetchSkills = async () => {
      setIsLoading(true);
      const fetchedSkills = await getSkills();
      setSkills(fetchedSkills);
      setIsLoading(false);
    };

    fetchSkills();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <AuthPageLayout containerWidth='lg'>
        <Grid container direction="column">
            <Paper
              sx={{
                p: 5,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
              }}
            >
              <Typography variant="h6" sx={{paddingBottom:1, fontWeight:"bold"}}>
                Skills
              </Typography>
              {
                isLoading ?
                (<LoadingIndicator />) :
                (
                  <div style={{ height: 390, width: '100%' }}>
                    <div style={{ display: 'flex', height: '100%' }}>
                      <div style={{ flexGrow: 1 }}>
                        <DataGrid
                          {...(isAdmin() ? {components: { Toolbar: () => <CustomToolbar addAction={createSkill}/> }}: {})}
                          sx={{ fontSize: 16, '& .header-style': {
                            fontSize: 18,
                          },}}
                          rows={skills}
                          columns={skillsTableColumns}
                          getRowId={(skill) => skill.id}
                          pageSize={10}
                          rowsPerPageOptions={[5]}
                          disableSelectionOnClick
                          disableColumnResize={false}
                          checkboxSelection={false}
                          autoPageSize={true}
                          getRowHeight={() => 'auto'}
                        />
                      </div>
                    </div>
                  </div>
                )
              }
            </Paper>
          </Grid>
          <CustomModal
          {...createSkillModalProps}
          open={openCreateModal}
          handleClose={() => setOpenCreateModal(false)}
          handleConfirm={() => setOpenCreateModal(false)}
          handleCancel={() => setOpenCreateModal(false)}
          children={<h1>{messageCreate}</h1>}
        />
      </AuthPageLayout>
    </>
  )
};
