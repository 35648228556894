import { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import AuthPageLayout from "../layout/AuthPageLayout";
import LoadingIndicator from "../components/common/LoadingSpinner";
import { useUserAPI } from "../context/userAPIContext";
import { CustomToolbar } from "../components/common/CustomDataGridToolBar";
import { CustomModal } from "../components/common/CustomModal";
import { ADMIN_ROLE } from "../utils/constants";
import { getCategories, postCategories } from "../services/api/categories";

const createCategorieModalProps = { 
  title: 'Create Category',
  description:
    'Backend response to create category:',
  confirmButtonText: 'Create',
  cancelButtonText: 'Cancel',
  confirmButtonColor: 'primary',
  variant: 'outlined',
};

export default function Categories() {
  const categoriesColumns = [
    {
      field: 'title',
      headerName: 'TITLE',
      minWidth: 150,
      flex: 1,
      headerClassName: 'header-style',
      headerAlign: 'center'
    },
    {
      field: 'description',
      headerName: 'DESCRIPTION',
      minWidth: 150,
      flex: 1,
      headerClassName: 'header-style',
      headerAlign: 'center'
    }
  ]
  
  const isAdmin = () => user?.roles?.demo && user.roles.demo.includes(ADMIN_ROLE);

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const { user, checkUserAuthentication } = useUserAPI();
  const [ messageCreate, setMessageCreate] = useState('');

  const createCategory = async () => {
    setOpenCreateModal(true)
    const message = await postCategories();
    setMessageCreate(message);
  };

  useEffect(() => {
    checkUserAuthentication();
    const fetchCategories = async () => {
      setIsLoading(true);
      const fetchedCategories = await getCategories();
      setCategories(fetchedCategories);
      setIsLoading(false);
    };

    fetchCategories();
    // eslint-disable-next-line
  }, []);

  return (
      <AuthPageLayout containerWidth='lg'>
        <Grid container direction="column">
          <Paper
            sx={{
              p: 5,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
            }}
          >
            <Typography variant="h6" sx={{paddingBottom:1, fontWeight:"bold"}}>
              Categories
            </Typography>
            {
              isLoading ?
              (<LoadingIndicator />) :
              (
                <div style={{ height: 390, width: '100%' }}>
                  <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                      <DataGrid
                        {...(isAdmin() ? {components: { Toolbar: () => <CustomToolbar addAction={createCategory}/> }}: {})}
                        sx={{ fontSize: 16, '& .header-style': {
                          fontSize: 18,
                        },}}
                        rows={categories}
                        columns={categoriesColumns}
                        getRowId={(categorie) => categorie.id}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        disableColumnResize={false}
                        checkboxSelection={false}
                        autoPageSize={true}
                        getRowHeight={() => 'auto'}
                      />
                    </div>
                  </div>
                </div>
              )
            }
          </Paper>
        </Grid>
        <CustomModal
          {...createCategorieModalProps}
          open={openCreateModal}
          handleClose={() => setOpenCreateModal(false)}
          handleConfirm={() => setOpenCreateModal(false)}
          handleCancel={() => setOpenCreateModal(false)}
          children={<h1>{messageCreate}</h1>}
        />

      </AuthPageLayout>

      
  );
}
