import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Login from "./pages/Login";
import Categories from "./pages/Categories";
import { ADMIN_ROLE, ANY_ROLE, HR_ROLE } from "./utils/constants";
import { UserAPIContextProvider, useUserAPI } from "./context/userAPIContext";
import { Users } from "./pages/Users";
import { Skills } from "./pages/Skills";
import { Positions } from "./pages/Positions";
import { Teams } from "./pages/Teams";
import { Clients } from "./pages/Clients";
import { ThemeProvider } from '@emotion/react';

const ProtectedRoute = ({ element, roles = [ANY_ROLE]}) => {
  const { user, theme } = useUserAPI();
  
  if (user && (roles[0] === ANY_ROLE || roles.some(role => user.roles.demo.includes(role)))) {
    return (
      <ThemeProvider theme={theme}>
        {element}
      </ThemeProvider>
    )
  }
  return <Navigate to="/login" replace />;
};

const LoginRoute = () => {
  const { user, theme } = useUserAPI();
  if (!user) {
    return (
      <ThemeProvider theme={theme}>
        <Login />
      </ThemeProvider>
    )
  }
  return <Navigate to="/dashboard" />;
};

const App = () => {
  return (
    <UserAPIContextProvider>
        <Router>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="*" element={<Navigate to="/login" />} />
            <Route path="/login" element={<LoginRoute />} />
            <Route
              path="/dashboard"
              element={<ProtectedRoute element={<Categories />} />}
            />
            <Route
              path="/users"
              element={<ProtectedRoute element={<Users />} roles={[ADMIN_ROLE]} />}
            />
            <Route
              path="/teams"
              element={<ProtectedRoute element={<Teams />} roles={[ADMIN_ROLE]} />}
            />
            <Route
              path="/skills"
              element={<ProtectedRoute element={<Skills />} />}
            />
            <Route
              path="/positions"
              element={<ProtectedRoute element={<Positions />} roles={[ADMIN_ROLE, HR_ROLE]}/>}
            />
            <Route
              path="/clients"
              element={<ProtectedRoute element={<Clients />} roles={[ADMIN_ROLE, HR_ROLE]}/>}
            />
          </Routes>
        </Router>
    </UserAPIContextProvider>
  );
};

export default App;
