import axios from "axios";
import EnvManager from '../../config/envManager';

const backend = axios.create({
  baseURL: EnvManager.BACKEND_URL,
  withCredentials: true
});

export const getUserPermissions = async () => {
  try {
    const response = await backend.get('/api/authz/user-permissions');
    return response?.status === 200 ? response?.data : null;
  }
  catch (error) {
    return null;
  }
};
