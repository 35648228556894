import axios from "axios";
import EnvManager from '../../config/envManager';

const backend = axios.create({
  baseURL: `${EnvManager.BACKEND_URL}/api`,
  withCredentials: true
});

export const getTeams = async () => {
  try {
    const response = await backend.get('/team');
    return response.data;
  }
  catch (error) {
    return null;
  }
};

export const postTeam = async () => {
  try {
    const response = await backend.post('/team');
    return response.data;
  } catch (error) {
    return null;
  }
};
