import axios from "axios";
import EnvManager from '../../config/envManager';

const backend = axios.create({
    baseURL: EnvManager.AUTH_URL,
    withCredentials: true
});

export const logout = async () => {
  try {
    await backend.get('/authn/logout');
  }
  catch (error) {
      return null;
  }
};
