import axios from "axios";
import EnvManager from '../../config/envManager';

const backend = axios.create({
  baseURL: `${EnvManager.BACKEND_URL}/api`,
  withCredentials: true
});

export const getOpenPositions = async () => {
  try {
    const response = await backend.get('/openPositions');
    return response.data;
  }
  catch (error) {
    return null;
  }
};

export const postOpenPosition = async () => {
  try {
    const response = await backend.post('/openPositions');
    return response.data;
  } catch (error) {
    return null;
  }
};
