import { Grid, Paper } from "@mui/material";
import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";

import AuthPageLayout from "../layout/AuthPageLayout";
import LoadingIndicator from "../components/common/LoadingSpinner";
import { getClients } from "../services/api/clients";

export const Clients = () => {

  const clientsTableColumns = [
    {
      field: 'title',
      headerName: 'NAME',
      minWidth: 150,
      flex: 1,
      headerClassName: 'header-style',
      headerAlign: 'center'
    },
    {
      field: 'description',
      headerName: 'DESCRIPTION',
      minWidth: 150,
      flex: 1,
      headerClassName: 'header-style',
      headerAlign: 'center'
    }
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const fetchClients = async () => {
      setIsLoading(true);
      const fetchedClients = await getClients();
      setClients(fetchedClients);
      setIsLoading(false);
    };

    fetchClients();
    // eslint-disable-next-line
  }, []);


  return (
    <>
      <AuthPageLayout containerWidth='lg'>
        <Grid container direction="column">
            <Paper
              sx={{
                p: 5,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
              }}
            >
              <Typography variant="h6" sx={{paddingBottom:1, fontWeight:"bold"}}>
                Clients
              </Typography>
              {
                isLoading ?
                (<LoadingIndicator />) :
                (
                  <div style={{ height: 390, width: '100%' }}>
                    <div style={{ display: 'flex', height: '100%' }}>
                      <div style={{ flexGrow: 1 }}>
                        <DataGrid
                          sx={{ fontSize: 16, '& .header-style': {
                            fontSize: 18,
                          },}}
                          rows={clients}
                          columns={clientsTableColumns}
                          getRowId={(client) => client.id}
                          pageSize={10}
                          rowsPerPageOptions={[5]}
                          disableSelectionOnClick
                          disableColumnResize={false}
                          checkboxSelection={false}
                          autoPageSize={true}
                          getRowHeight={() => 'auto'}
                        />
                      </div>
                    </div>
                  </div>
                )
              }
            </Paper>
          </Grid>
      </AuthPageLayout>
    </>
  )
};
