import { createContext, useContext, useEffect, useState } from "react";
import { getUserPermissions } from "../services/auth/authorization";
import { createTheme } from '@mui/material';
import { purple } from '@mui/material/colors';

const UserAPIContext = createContext();

const baseTheme = createTheme({
  palette: {
    primary: {
      main: purple[500],
    },
  },
});

export const UserAPIContextProvider = ({ children }) => {
  const [user, setUser] = useState(
    sessionStorage.getItem("user") !== null
      ? JSON.parse(sessionStorage.getItem("user"))
      : null
  );

  const [theme, setTheme] = useState(baseTheme);

  const fetchAndCheckUserPermissions = async () => {
    const user = await getUserPermissions();
    if (user) {
      sessionStorage.setItem("user", JSON.stringify(user));
    } else {
      sessionStorage.clear();
    }
    setUser(user);
  };

  useEffect(() => {
    fetchAndCheckUserPermissions();
  }, []);

  return (
    <UserAPIContext.Provider
      value={{ user, checkUserAuthentication: fetchAndCheckUserPermissions, theme, setTheme, defaultTheme: baseTheme }}
    >
      {children}
    </UserAPIContext.Provider>
  );
};

export const useUserAPI = () => {
  const context = useContext(UserAPIContext);
  if (!context) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};
